import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "Quels sont les prérequis pour le brevet spécialiste en conduite d'équipe?",
        value: "Quels sont les prérequis pour le brevet spécialiste en conduite d'équipe?"
    },
    { text: "Qu'est ce qui differencie Cursus Formation d'autres entreprises de formations?", value: "Qu'est ce qui differencie Cursus Formation d'autres entreprises de formations?" },
    { text: "A quelles subventions ai-je droit pour un brevet à Genève?", value: "A quelles subventions ai-je droit pour un brevet à Genève?" }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
