import { Outlet } from "react-router-dom";
import { BookContacts20Filled, ContactCard16Filled } from "@fluentui/react-icons";

import logo from "../../assets/logoCFfull.png";

import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <a href="https://www.cursus-formation.ch/" target={"_blank"} title="Cursus formation link">
                        <img
                            src={logo}
                            alt="CF logo"
                            aria-label="Link to github repository"
                            //width="30px"
                            //height="30px"
                            className={styles.githubLogo}
                        />
                    </a>
                    <a className={styles.navbarButton} href="mailto:info@cursus-formation.ch">
                        <span>Signaler un problème</span>
                    </a>
                    <button className={styles.infoButton}>
                        ℹ️
                        <span className={styles.tooltipText}>
                            Ce contenu est généré par l'IA et peut être incorrect. Pour toute question, veuillez nous contacter en cliquant{" "}
                            <a href="https://www.cursus-formation.ch/contact/" target="_blank" className={styles.tooltipLink}>
                                ici
                            </a>
                            .
                        </span>
                    </button>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
